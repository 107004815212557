import app from 'firebase/app'
import React from 'react'

import { Box, Divider, Grid, IconButton } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import Stack from '@mui/material/Stack'
import {
    Add as AddIcon,
    HomeOutlined as HomeIcon,
    /* Icons */ InfoOutlined as InfoIcon,
    Link as LinkIcon,
    FolderOutlined as FolderIcon,
    InsertDriveFileOutlined as FileIcon,
    ChevronRightRounded as ArrowIcon,
} from '@material-ui/icons'

import { Browser } from '@capacitor/browser'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import { TumbleWeedOutlinedIcon, GridIcon, ListIcon, /* Icons */ GoogleDriveIcon, PDFIcon, ImageIcon, WordIcon, VideoIcon } from 'components/Icons'

import { Link } from 'react-router-dom'

import NavigationBar from 'components/NavigationBar'

import Card from 'components/Card'
import DriveCreateItem from './components/DriveCreateItem'
import DriveItemInfo from './components/DriveItemInfo'

import CreateFolderDialog from './components/CreateFolderDialog'
import CreateLinkDialog from './components/CreateLinkDialog'
import DriveList from './components/DriveList'
import RenameFileDialog from './components/RenameFileDialog'
import MoveToDialog from './components/MoveToDialog'
import UploadFileDialog from './components/UploadFileDialog'

import DeleteDialog from 'components/Dialogs/DeleteDialog'
import FileViewer from 'components/FileViewer'

import { pdfjs } from 'react-pdf'

import { convertListToObject, isMobileDevice, combinePaths, getRelativeTime, convertObjectToList } from 'code/Helper'

// styles
import { useTheme } from '@material-ui/styles'
import useStyles from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `${isMobileDevice() ? 'https:' : ''}//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function Drive(props) {
    var theme = useTheme()
    const classes = useStyles()

    const db = app.firestore()

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const [createFolderDialog, setCreateFolderDialog] = React.useState(false)
    const [createLinkDialog, setCreateLinkDialog] = React.useState(false)
    const [uploadFileDialog, setUploadFileDialog] = React.useState(false)
    const [renameFileDialog, setRenameFileDialog] = React.useState(false)
    const [moveToDialog, setMoveToDialog] = React.useState(false)
    const [deleteFileDialog, setDeleteFileDialog] = React.useState(false)
    const [deleteFileDialogLoading, setDeleteFileDialogLoading] = React.useState(false)

    const [view, _setView] = React.useState(localStorage.getItem('drive_viewType') ?? 'grid')

    const setView = v => {
        localStorage.setItem('drive_viewType', v)
        _setView(v)
    }

    let path = ''
    const paths = []

    if (props.match.params !== undefined && props.match.params[0]) {
        let possiblePaths = props.match.params[0].split('/').filter(id => id.length > 0)

        for (let i = 0; i < possiblePaths.length; i++) {
            let possiblePath = possiblePaths[i]
            paths.push(possiblePath)
            path = possiblePath
        }
    }

    const [items, setItems] = React.useState({})

    const [curItems, setCurItems] = React.useState([])

    const [onAdd, setOnAdd] = React.useState(false)
    const [viewInfo, setViewInfo] = React.useState(null)

    const [viewItem, setViewItem] = React.useState(null)

    const history = props.history

    const hasCreateAdmin = id => {
        if (!user || !chapter) {
            return false
        }

        if (user.getId() in chapter.members) {
            let mem = chapter.members[user.getId()]

            if (
                mem.role === 'ADMIN' ||
                (chapter.perms.driveAdmin && chapter.perms['driveAdmin'].includes(mem.role)) ||
                (chapter.perms.driveCreate && chapter.perms['driveCreate'].includes(mem.role) && items[id] && items[id].author.id === user.getId())
            ) {
                return true
            }
        }

        return false
    }

    const canCreateDriveFile = () => {
        if (!user || !chapter) {
            return false
        }

        if (user.getId() in chapter.members) {
            let mem = chapter.members[user.getId()]

            if (
                mem.role === 'ADMIN' ||
                (chapter.perms.driveAdmin && chapter.perms['driveAdmin'].includes(mem.role)) ||
                (chapter.perms.driveCreate && chapter.perms['driveCreate'].includes(mem.role))
            ) {
                return true
            }
        }

        return false
    }

    const [canCreate] = React.useState(canCreateDriveFile())

    const downloadItem = async id => {
        let data = items[id]
        var storageRef = app.storage().ref()
        storageRef
            .child(data.file)
            .getDownloadURL()
            .then(url => {
                fetch(url)
                    .then(result => result.blob())
                    .then(blob => {
                        const name = `${data.name}.${data.extension}`

                        const element = document.createElement('a')
                        const file = new File([blob], name)

                        if (isMobileDevice() && navigator.canShare && navigator.canShare({ files: [file] })) {
                            navigator.share({
                                files: [file],
                            })
                        } else {
                            const url = URL.createObjectURL(blob)
                            element.href = url
                            element.download = name
                            document.body.appendChild(element) // Required for this to work in FireFox
                            element.click()
                        }
                    })
            })
    }

    const getAuthor = () => {
        if (user) {
            return {
                id: user.getId(),
                first: user.first,
                last: user.last,
                photo: user.photoURL ? user.photoURL : '',
            }
        }
    }

    const [isUploadingFile, setIsUploadingFile] = React.useState(false)

    const onUploadFile = async ({ name, file, preview, extension, vis, ...props }) => {
        setIsUploadingFile(true)

        let newDriveItemDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('drive')
            .doc()

        let fileName = `chapters/${user.getChapter()}/drive/${newDriveItemDoc.id}.${extension}`

        var storageRef = app.storage().ref()

        await storageRef.child(fileName).put(file)

        let data = {
            name: name,
            type: 'file',
            file: fileName,
            extension: extension,
            vis: vis,
            lu: new Date(),
        }

        let author = getAuthor()

        if (author) {
            data.author = author
        }

        if (preview) {
            let previewImageName = `chapters/${user.getChapter()}/drive/${newDriveItemDoc.id}_preview.png`
            let previewTrimmed = preview.substring(22)

            let previewStorageRef = storageRef.child(previewImageName)
            await previewStorageRef.putString(previewTrimmed, 'base64', { 
                cacheControl: 'private, max-age=15552000', 
                contentType: 'image/png',
                customMetadata: {
                    userId: user.getId(),
                    chapterId: user.getChapter(),
                }
            })

            data.preview = await previewStorageRef.getDownloadURL()
            data.previewRef = previewImageName
        }

        if (path === '') {
            data.base = true
        } else {
            data.parent = path
        }

        await newDriveItemDoc.set(data)

        setIsUploadingFile(false)
        setUploadFileDialog(false)
    }

    const renameFile = ({ name, id }) => {
        let curDriveItemRef = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('drive')
            .doc(id)

        curDriveItemRef.update({ name: name })
    }

    const onCreateLink = ({ name, url, vis }) => {
        const db = app.firestore()

        let newDriveItemDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('drive')
            .doc()

        let data = {
            name: name,
            type: 'link',
            url: url,
            vis: vis,
            lu: new Date(),
        }

        let author = getAuthor()

        if (author) {
            data.author = author
        }

        if (path === '') {
            data.base = true
        } else {
            data.parent = path
        }

        newDriveItemDoc.set(data)
    }

    const deleteFile = async fileId => {
        if (fileId) {
            setDeleteFileDialogLoading(true)
            await db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('drive')
                .doc(fileId)
                .delete()
            setDeleteFileDialog(false)
            setDeleteFileDialogLoading(false)
        }
    }

    const onCreateFolder = ({ name, vis }) => {
        let newDriveItemDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('drive')
            .doc()

        let data = {
            name: name,
            type: 'folder',
            vis: vis,
            lu: new Date(),
        }

        let author = getAuthor()

        if (author) {
            data.author = author
        }

        if (path === '') {
            data.base = true
        } else {
            data.parent = path
        }

        newDriveItemDoc.set(data)
    }

    const [hasLoaded, setHasLoaded] = React.useState(false)
    const [hasGottenFolders, setHasGottenFolders] = React.useState(false)

    const getFolders = async () => {
        if (!hasGottenFolders) {
            let query = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('drive')
                .where('type', '==', 'folder')

            // They are not an admin
            if (chapter && chapter.members && user.getId() in chapter.members && chapter.members[user.getId()].role !== 'ADMIN') {
                query = query.where('vis', 'array-contains', chapter.members[user.getId()].status)
            }

            const docs = await query.get()

            let items = {}

            setHasLoaded(true)

            docs.forEach(function(doc) {
                let data = doc.data()
                items[doc.id] = data
            })

            setItems(i => ({ ...i, ...items }))
            setHasGottenFolders(true)
        }
    }

    const getFoldersAndMove = async (id) => {
        await getFolders()
        setMoveToDialog(id)
    }

    React.useEffect(() => {
        let query = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('drive')

        if (path === '') {
            query = query.where('base', '==', true)
        } else {
            query = query.where('parent', '==', path)
        }

        // They are not an admin
        if (chapter && chapter.members && user.getId() in chapter.members && chapter.members[user.getId()].role !== 'ADMIN') {
            query = query.where('vis', 'array-contains', chapter.members[user.getId()].status)
        }

        setHasLoaded(false)

        let unsubscribe = query.onSnapshot(
            function(querySnapshot) {
                let items = {}
                let curItems = []

                setHasLoaded(true)

                querySnapshot.forEach(function(doc) {
                    let data = doc.data()
                    curItems.push(doc.id)
                    items[doc.id] = data
                })

                setItems(i => ({ ...i, ...items }))
                setCurItems(curItems)
            },
            function(error) {
                console.log('Error getting documents: ', error)
            },
        )

        paths.forEach(path => {
            if (!(path in items)) {
                db.collection('chapters')
                    .doc(user.getChapter())
                    .collection('drive')
                    .doc(path)
                    .get()
                    .then(doc => {
                        if (doc.exists) {
                            setItems(i => ({ ...i, [path]: doc.data() }))
                        }
                    })
            }
        })

        return () => {
            unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path])

    const getTitles = () => {
        let titles = [
            {
                name: 'My House',
                link: '/app/dashboard/',
                icon: <HomeIcon />,
            },
        ]

        let hiddenTitles = []

        if (path) {
            titles.push({ name: 'Drive', link: '/app/applications/drive/' })

            let totalPath = '/app/applications/drive'

            for (let i = 0; i < paths.length; i++) {
                let curPath = paths[i]
                let isLastPath = i >= paths.length - 1

                let title = items[curPath] ? items[curPath].name : curPath

                totalPath = combinePaths(totalPath, curPath)

                if (isLastPath) {
                    if (hiddenTitles.length > 0) {
                        titles.push({
                            collapsed: hiddenTitles,
                        })
                    }
                    titles.push({ name: title })
                } else {
                    hiddenTitles.push({ name: title, link: totalPath })
                }

                if (title !== curPath) sessionStorage.setItem(totalPath, title)
            }
        } else {
            titles.push({ name: 'Drive' })
        }

        return { titles, hiddenTitles }
    }

    let getSortedItems = React.useCallback(() => {
        let is = curItems.map(item => ({ ...items[item], id: item }))

        is.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        return is.map(item => item.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curItems])

    const getGridButtons = () => {
        const items = []

        if (view === 'list') {
            items.push({
                name: 'List',
                type: 'icon',
                innerIcon: <ListIcon />,
                onClick: () => {
                    setView('grid')
                },
            })
        } else {
            items.push({
                name: 'Grid',
                type: 'icon',
                innerIcon: <GridIcon />,
                onClick: () => {
                    setView('list')
                },
            })
        }

        if (canCreate) {
            items.push({
                name: 'Add',
                type: 'icon',
                innerIcon: <AddIcon />,
                onClick: e => {
                    setOnAdd(e.currentTarget)
                },
            })
        }

        return items
    }

    const convertFolders = (_items) => {
        const items = convertObjectToList(_items).filter(i => i.type === 'folder')
        const base = items.filter(i => !!i.base)

        const convertItem = i => ({ id: i.id, name: i.name, vis: i.vis, folders: items.filter(j => j.parent === i.id).map(convertItem) })

        return base.map(convertItem)
    }
    
    const moveFolder = async ({ id, parent }) => {
        let curDriveItemRef = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('drive')
            .doc(id)

        if (!parent) {
            await curDriveItemRef.update({ parent: app.firestore.FieldValue.delete(), base: true })
        } else {
            await curDriveItemRef.update({ parent, base: app.firestore.FieldValue.delete() })
        }
    }

    const convertedFolders = React.useMemo(() => convertFolders(items), [items])

    const renderNavBar = isGrid => <NavigationBar key={`${path}.${view}`} {...getTitles()} rightButtons={getGridButtons()} grid={isGrid} className={classes.navBarContainer} />

    const parentFolder = paths && paths.length > 0 && paths[paths.length - 1] in items ? items[paths[paths.length - 1]] : false

    const renderDrivePopups = () => (
        <>
            <DriveCreateItem
                open={!!onAdd}
                target={onAdd}
                onClose={() => setOnAdd(false)}
                type={isMobileDevice() ? 'sheet' : 'menu'}
                onSelectAction={action => {
                    if (action === 'link') {
                        setCreateLinkDialog(true)
                    } else if (action === 'folder') {
                        setCreateFolderDialog(true)
                    } else if (action === 'file') {
                        setUploadFileDialog(true)
                    }

                    setOnAdd(false)
                }}
                disableFolder={paths.length >= 9}
            />
            <DriveItemInfo
                open={viewInfo !== null}
                onClose={() => setViewInfo(null)}
                onSelectAction={action => {
                    if (action === 'delete') {
                        setDeleteFileDialog(viewInfo)
                    } else if (action === 'rename') {
                        setRenameFileDialog(viewInfo)
                    } else if (action === 'move') {
                        // setMoveFileDialog(viewInfo)
                        getFoldersAndMove(viewInfo)
                    } else if (action === 'download') {
                        downloadItem(viewInfo)
                    }

                    setViewInfo(null)
                }}
                hasAdmin={hasCreateAdmin(viewInfo)}
                info={convertListToObject(items)[viewInfo]}
                history={history}
            />
            <CreateFolderDialog
                open={createFolderDialog}
                onClose={() => setCreateFolderDialog(false)}
                onCreate={name => onCreateFolder(name)}
                parentVis={parentFolder ? parentFolder.vis : null}
            />
            <CreateLinkDialog
                open={createLinkDialog}
                onClose={() => setCreateLinkDialog(false)}
                onCreate={val => onCreateLink(val)}
                parentVis={parentFolder ? parentFolder.vis : null}
            />
            <UploadFileDialog
                open={uploadFileDialog}
                isLoading={isUploadingFile}
                onClose={() => {
                    if (!isUploadingFile) {
                        setUploadFileDialog(false)
                    }
                }}
                onUpload={val => onUploadFile(val)}
                parentVis={parentFolder ? parentFolder.vis : null}
            />
            <RenameFileDialog
                open={renameFileDialog}
                onClose={() => setRenameFileDialog(false)}
                onRenameFile={name => renameFile({ id: renameFileDialog, name: name })}
                name={renameFileDialog in items ? items[renameFileDialog].name : null}
            />
            <MoveToDialog
                open={moveToDialog}
                onClose={() => setMoveToDialog(false)}
                items={items}
                file={moveToDialog in items ? { ...items[moveToDialog], icon: getIcon(items[moveToDialog]), id: moveToDialog } : null}
                folders={convertedFolders}
                onMove={(folderId) => {
                    moveFolder({ id: moveToDialog, parent: folderId })
                    setMoveToDialog(false)
                }}
            />
            <DeleteDialog
                title={`Delete ${deleteFileDialog ? items[deleteFileDialog].type : 'untyped'}?`}
                text={
                    <span>
                        Are you sure you want to delete <span style={{ fontWeight: 700 }}>{deleteFileDialog ? items[deleteFileDialog].name : 'unnamed'}</span>?
                        <br />
                        {deleteFileDialog && items[deleteFileDialog].type === 'folder'
                            ? 'Deleting this folder will delete all items inside of it, and cannot be undone!'
                            : 'This action cannot be undone!'}
                    </span>
                }
                open={!!deleteFileDialog}
                onClose={() => {
                    setDeleteFileDialog(false)
                }}
                onConfirmDelete={() => {
                    deleteFile(deleteFileDialog)
                }}
                loading={deleteFileDialogLoading}
            />
            {viewItem !== null && (
                <FileViewer
                    open={viewItem !== null}
                    onClose={() => {
                        setViewItem(null)
                    }}
                    onSelectAction={action => {
                        if (action === 'download') {
                            downloadItem(viewItem.id)
                        }

                        setViewInfo(null)
                    }}
                    src={viewItem ? viewItem.src : null}
                    onSelectInfo={() => setViewInfo(viewItem.id)}
                    isObj
                    title={viewItem ? viewItem.name : ''}
                    extension={viewItem ? viewItem.extension : null}
                />
            )}
        </>
    )

    const getIcon = data => {
        if (data.type === 'file') {
            if (data.extension === 'doc' || data.extension === 'docx') {
                return WordIcon
            }
            if (data.extension === 'pdf') {
                return PDFIcon
            }
            if (['jpg', 'jpeg', 'png', 'gif', 'webp', 'tiff', 'svg'].includes(data.extension)) {
                return ImageIcon
            }
            if (data.extension === 'mp4' || data.extension === 'mov') {
                return VideoIcon
            }
            return FileIcon
        }

        if (data.type === 'drive') {
            return GoogleDriveIcon
        }

        if (data.type === 'folder') {
            return FolderIcon
        }

        if (data.type === 'link') {
            return LinkIcon
        }

        return <></>
    }

    const getCardImage = itemId => {
        const item = items[itemId]
        return item.type === 'file' || (item.type === 'link' && item.preview) ? item.preview : item.og && item.og.ogImage ? item.og.ogImage : null
    }

    const getCardProps = itemId => {
        const item = items[itemId]

        if (!item) return {}

        if (item.type === 'folder')
            return {
                component: Link,
                to: combinePaths(history.location.pathname, itemId),
            }

        if (item.type === 'file')
            return {
                onClick: () => {
                    var storageRef = app.storage().ref()
                    storageRef
                        .child(item.file)
                        .getDownloadURL()
                        .then(url => {
                            let extension = item.extension

                            if (extension === 'pdf') {
                                setViewItem({ id: itemId, src: url, extension: item.extension, name: item.name })
                            } else {
                                Browser.open({ url: url })
                            }
                        })
                },
            }

        return {
            component: 'a',
            href: item.url,
            target: '_blank',
        }
    }

    const onClick = itemId => {
        const item = items[itemId]

        if (!item) return

        if (item.type === 'folder') {
            props.history.push(combinePaths(history.location.pathname, itemId))
        } else if (item.type === 'file') {
            var storageRef = app.storage().ref()
            storageRef
                .child(item.file)
                .getDownloadURL()
                .then(url => {
                    let extension = item.extension

                    if (extension === 'pdf') {
                        setViewItem({ id: itemId, src: url, extension: item.extension, name: item.name })
                    } else {
                        Browser.open({ url: url })
                    }
                })
        } else {
            Browser.open({ url: item.url })
        }
    }

    // When the view is grid or it's a mobile device that is not empty
    if (view === 'grid' && (!isMobileDevice() || !(hasLoaded && curItems.length === 0))) {
        const compiledItems = curItems.filter(i => i in items).map(i => ({ ...items[i], id: i, icon: getIcon(items[i]) }))

        if (isMobileDevice()) {
            return (
                <>
                    <Stack direction="column" spacing={2}>
                        {renderNavBar(false)}
                        <Stack direction="column">
                            {compiledItems &&
                                compiledItems.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => (
                                    <React.Fragment key={item.id}>
                                        {index > 0 && <Divider sx={{ ml: 5, mr: 5.25 }} />}
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <ButtonBase
                                                sx={{
                                                    flexGrow: 1,
                                                    width: '100%',
                                                    textAlign: 'left',
                                                    p: 1,
                                                    borderTopLeftRadius: index === 0 ? 4 : 0,
                                                    borderTopRightRadius: index === 0 ? 4 : 0,
                                                    borderBottomLeftRadius: index === curItems.length - 1 ? 4 : 0,
                                                    borderBottomRightRadius: index === curItems.length - 1 ? 4 : 0,
                                                }}
                                                {...getCardProps(item.id)}
                                            >
                                                <Stack direction="row" spacing={1} key={item.id} alignItems="center" sx={{ width: '100%' }}>
                                                    {React.createElement(item.icon)}
                                                    <Stack direction="column" sx={{ flexGrow: 1 }}>
                                                        <Typography>{item.name}</Typography>
                                                        <Typography sx={{ lineHeight: 1, fontSize: '0.75rem' }}>
                                                            Modified {getRelativeTime(item.lu)}
                                                        </Typography>
                                                    </Stack>
                                                    <ArrowIcon />
                                                </Stack>
                                            </ButtonBase>
                                            <IconButton size="small" onClick={() => setViewInfo(item.id)}>
                                                <InfoIcon />
                                            </IconButton>
                                        </Stack>
                                    </React.Fragment>
                                ))}
                        </Stack>
                    </Stack>
                    {renderDrivePopups()}
                </>
            )
        }

        return (
            <>
                <Stack direction="column" spacing={2} sx={{ height: '100%' }} className={classes.table}>
                    {renderNavBar(false)}
                    <DriveList loading={!hasLoaded} rows={compiledItems} onViewInfo={id => setViewInfo(id)} onClick={onClick} />
                </Stack>
                {renderDrivePopups()}
            </>
        )
    }

    if (hasLoaded && curItems.length === 0) {
        return (
            <>
                <Box>{renderNavBar(false)}</Box>
                <Box style={{ padding: 8, width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 16,
                            flexGrow: 1,
                        }}
                    >
                        <TumbleWeedOutlinedIcon style={{ fontSize: '7.5em', transform: 'rotate(-25deg)', color: theme.borderColor }} />
                        <Typography sx={{ color: theme.borderColor }}>There are no files here</Typography>
                    </Box>
                </Box>
                {renderDrivePopups()}
            </>
        )
    }

    return (
        <>
            <Grid container spacing={2}>
                {renderNavBar(true)}
                {getSortedItems().map(curItem => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={curItem}>
                        {curItem in items && (
                            <Card
                                style={{ height: 196 }}
                                title={items[curItem].name}
                                variant={isMobileDevice() ? 'outlined' : 'card'}
                                buttonProps={getCardProps(curItem)}
                                image={getCardImage(curItem)}
                                secondaryIcons={[
                                    {
                                        label: 'Info',
                                        onClick: () => {
                                            setViewInfo(curItem)
                                        },
                                        icon: <InfoIcon />,
                                        id: `${curItem}.info`,
                                    },
                                ]}
                                icon={getIcon(items[curItem])}
                                animate
                            />
                        )}
                    </Grid>
                ))}
            </Grid>
            {renderDrivePopups()}
        </>
    )
}
