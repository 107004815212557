import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
// import { useChapterPoints } from 'hooks/queries/useChapterPoints';
// Import Swiper styles - changed to use bundle
import 'swiper/swiper-bundle.css';
import { makeStyles } from '@material-ui/styles'
import { ChapterContext } from 'context/ChapterContext';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const useStyles = makeStyles({
  activityFeedContainer: {
    maxWidth: 800,
    margin: '0 auto',
    padding: 20,
  },
  activityCard: {
    background: '#fff',
    borderRadius: 8,
    padding: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '10px 0',
  },
  cardTitle: {
    margin: '0 0 10px 0',
    color: '#333',
  },
  cardDescription: {
    margin: '0 0 10px 0',
    color: '#666',
  },
  timestamp: {
    fontSize: '0.8em',
    color: '#999',
  },
  activityFeedSwiper: {
    padding: '20px 0 40px 0',
    '& .swiper-button-next, & .swiper-button-prev': {
      display: 'none',
    },
  },
});

const ActivityFeed = () => {
  const classes = useStyles();
  const { chapter } = useContext(ChapterContext)
  /*
  const { data: pointSystems, isLoading, error } = useChapterPoints({
    chapterId: chapterId,
    userId: userId,
    memberStatus: memberStatus
  });
  */

  // console.log(pointSystems)
  
  // Example activity data - replace with your actual data
  const activities = [
    {
      id: 1,
      title: 'New Project Created',
      description: 'John Doe created a new project',
      timestamp: '2 hours ago',
    },
    {
      id: 2,
      title: 'Task Completed',
      description: 'Jane Smith completed the design task',
      timestamp: '4 hours ago',
    },
    {
      id: 3,
      title: 'Comment Added',
      description: 'Mike Johnson commented on your post',
      timestamp: '5 hours ago',
    },
  ];

  return (
    <div className={classes.activityFeedContainer}>
      <h2>Activity Feed</h2>
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        className={classes.activityFeedSwiper}
      >
        {activities.map((activity) => (
          <SwiperSlide key={activity.id}>
            <div className={classes.activityCard}>
              <h3 className={classes.cardTitle}>{activity.title}</h3>
              <p className={classes.cardDescription}>{activity.description}</p>
              <span className={classes.timestamp}>{activity.timestamp}</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ActivityFeed;
